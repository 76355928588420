require('../css/app.css');

// CSS
//Закомментил. Пока вызывает проблемы. Вообще ребята говорили, что так нужно подключать вместо пакета
// import "../../public/css/pal.min.css";
import "../../public/css/fancybox.min.css";

// JS
import tippy from 'tippy.js';

//Закомментил. Пока вызывает проблемы. Вообще ребята говорили, что так нужно подключать вместо пакета
// import "../../public/js/pal.min"


function renderLink(resp) {
    let html = '';
    html += '<a class="pal-content__link" href="'+resp.getAttribute('href')+'">';
    html += '   <div class="parts-row parts-space-md parts-md parts-nowrap ">';
    html += '       <div class="col"><img src="/images/ic-doc.svg" alt="" style="min-width: 40px;"></div>';
    html += '       <div class="col">';
    html += '           <span class="text-md-md"><strong>'+resp.text+'</strong></span>';
    html += '       </div>';
    html += '   </div>';
    html += '</a>';

    return html;
}

function checkFilter(origForm, form) {
    if (form === origForm) {
        $('#filter_button').removeAttr('type').attr('type', 'button');
        $('#filter_button').removeClass().addClass('pal-filter__item_custom_disabled');
    } else {
        $('#filter_button').removeAttr('type').attr('type', 'submit');
        $('#filter_button').removeClass().addClass('pal-filter__item_custom_active');
    }
}

function getMonthNumber(month) {
    switch (month.toLowerCase()) {
        case 'январь':
        case 'january':
            return '01';
            break;
        case 'февраль':
        case 'february':
            return '02';
            break;
        case 'март':
        case 'march':
            return '03';
            break;
        case 'апрель':
        case 'april':
            return '04';
            break;
        case 'май':
        case 'may':
            return '05';
            break;
        case 'июнь':
        case 'june':
            return '06';
            break;
        case 'июль':
        case 'july':
            return '07';
            break;
        case 'август':
        case 'august':
            return '08';
            break;
        case 'сентябрь':
        case 'september':
            return '09';
            break;
        case 'октябрь':
        case 'october':
            return '10';
            break;
        case 'ноябрь':
        case 'november':
            return '11';
            break;
        case 'декабрь':
        case 'december':
            return '12';
    }
}

function unique(array){
    return array.filter(function(el, index, arr) {
        return index == arr.indexOf(el);
    });
}

function highlightDates() {
    let dates = [];
    $('.calendar-info__row').each(function () {
        dates.push($(this).data('date'));
    })

    dates.forEach((el) => {
        $('.datepicker--cell-day[data-date="'+el+'"]').addClass('payment');
    })
}

function getPayments(date) {
    let url = $('#datepicker').data('url');
    let locale = $('#datepicker').data('locale');

    $.ajax({
        method: 'POST',
        url: url,
        data: { 'date' : date.trim(), 'locale' : locale },
        success: function (data) {
            $('.c_info').html(data);

            highlightDates();
        },
        error: function (data) {
            console.log(data)
        }
    })
}

function contentClick(e) {
    if ($(e.target).hasClass('datepicker--cell-day')) {
        let day = $(e.target).data('date') < 10 ? '0' + $(e.target).data('date') : $(e.target).data('date');

        $('.calendar-info__row').css('display', 'none');
        $('.calendar-info__row[data-date="'+day+'"]').css('display', 'flex');

        highlightDates();

        return false;
    }

    if ($(this).parent().find('.datepicker--nav-title').text().split(',').length < 2) {
        return false;
    }

    let date = $(this).parent().find('.datepicker--nav-title').text().split(',');
    let monthNumber = getMonthNumber(date[0]);
    getPayments(date[1] + monthNumber + '01');
    $('.month').text(date[0] + ', ' + date[1]);
}

function navClick(el) {
    if (el.find('.datepicker--nav-title').text().split(',').length < 2) {
        return false;
    }

    let date = el.find('.datepicker--nav-title').text().split(',');
    let monthNumber = getMonthNumber(date[0]);
    getPayments(date[1] + monthNumber + '01');
    $('.month').text(date[0] + ', ' + date[1]);
}

function printContent(el){
    let restorePage = $('body').html();
    let printContent = el.clone();
    $('body').empty().html(printContent);
    window.print();
    window.location.reload();
}

function checkStatus() {
    let url = '?page=1&';
    $('.contract-status').each(function () {
        if ($(this).hasClass('pal-active')) {
            url += 'st[]=' + $(this).data('contract-st') + '&';
        }
    })

    let link = window.location.protocol + '//' + window.location.hostname + window.location.pathname + url;
    if (link.slice(-1) === '&') {
        link = link.substring(0, link.length - 1);
    }
    window.location.href = link;
}

// catalog hints
const price_template = document.getElementById('price_template');
const year_template = document.getElementById('year_template');
const manufacturers_template = document.getElementById('manufacturers_template');
const tippy_options = {
    content: '',
    allowHTML: true,
    animation: 'fade',
    trigger: 'click',
    interactive: true,
    interactiveBorder: 30,
    placement: 'bottom',
}
if (null !== price_template) {
    tippy_options.content = price_template.innerHTML
    tippy('#price_filter', tippy_options);
}
if (null !== year_template) {
    tippy_options.content = year_template.innerHTML
    tippy('#year_filter', tippy_options);
}
if (null !== manufacturers_template) {
    tippy_options.content = manufacturers_template.innerHTML
    tippy('#manufacturers_filter', tippy_options);
}
// end catalog hints

$(document).ready(function () {
    // account
    $('#order-report').click(function() {
        $.ajax({
            method: 'POST',
            url: $(this).data('url'),
            data: { 'order-report' : true },
            success: function (data) {
                $('#answer-text').html(data);
                //<span class="help-block pal-input__error">Заполните это поле</span>
            },
            error: function (data) {
                console.log(data)
            }
        })
    })
    $('.contract-status').click(function () {
        checkStatus();
    })

    $('.contract_filter').click(function (e) {
        $(this).prop('disabled', true);
        $('.loader').removeClass('pal-active');
        $('.loader_text').addClass('pal-active');
        e.preventDefault();
        $.ajax({
            method: 'POST',
            url: $(this).data('url'),
            data: {'date' : $('.contract_date').val()},
            success: function (data) {
                $('.contract_filter').removeAttr('disabled');
                $('.loader').addClass('pal-active');
                $('.loader_text').removeClass('pal-active');
                $('.pal-report').html(data)
                checkStatus();
            },
            error: function (data) {
                console.log(data)
            }
        })
    })

    $('#datepicker .datepicker--nav').click(function () {
        navClick($(this));
    })

    $('#datepicker .datepicker--content').click(function (e) {
        contentClick(e);
    })

    $('#datepickerMobile .datepicker--nav').click(function () {
        navClick($(this));
    })

    $('#datepickerMobile .datepicker--content').click(function (e) {
        contentClick(e)
    })

    let date = $('#datepicker').find('.datepicker--nav-title').text().split(',')
    let monthNumber = getMonthNumber(date[0]);
    getPayments(date[1] + monthNumber + '01');
    $('.month').text(date[0] + ', ' + date[1]);

    $('.calendar_print').on('click', function (e) {
        e.preventDefault();
        printContent($('#section-to-print'))
    })
    // end account

    // authentication
    $('#authentication_button').click(function(e) {
        $(this).prop('disabled', true);
        $('.loader').removeClass('pal-active');
        $('.loader_text').addClass('pal-active');
        e.preventDefault();
        let form = $('#authentication_form');
        $.ajax({
            method: form.attr('method'),
            url: form.data('url'),
            data: form.serialize(),
            success: function (data) {
                if (false === data.success) {
                    $('#authentication_button').removeAttr('disabled');
                    $('.loader').addClass('pal-active');
                    $('.loader_text').removeClass('pal-active');
                    $('.container_' + data.field).append('<span class="help-block pal-input__error">' + data.message + '</span>')
                    if (data.login_count) {
                        $('.container_login_count').removeClass('pal-active').addClass('pal-active');
                        $('.container_login_count .count').text(data.login_count)
                    }
                } else {
                    window.location.href = data.url;
                }
            },
            error: function (data) {
                console.log(data)
            }
        })
        grecaptcha.reset()
    })
    // end authentication

    // calculator
    $('#calculator_select').change(function () {
        let val = $(this).find(':selected').data('val');

        $('.calculator-container').removeClass('pal-active');
        $('#calculator_' + val).addClass('pal-active');
    })

    $('.js-range-slider').change(function () {
        let target = $(this).data('send');
        let calc = $(this).data('calc');
        $(target).val($(this).val());

        if($(this).hasClass('price_slider')) {
            $('.prepaid_sum_' + calc).text(Math.round($(this).val() * ($('.pre_s_' + calc).val()/100)));
        }

        if($(this).hasClass('prepaid_slider')) {
            $('.prepaid_sum_' + calc).text(Math.round(($(this).val()/100) * $('.pri_s_' + calc).val()));
            console.log($(this).val());
        }

        clearTimeout( $(this).data('timer') );

        let timer = setTimeout(function() {
            $.ajax({
                url :  '/calc_total',
                method: 'POST',
                data: $('#calculator-form_' + calc).serialize(),
                dataType : 'JSON',
                success : function (data) {
                    $('#total_sum_' + calc).text(data);
                }
            });
        }, 700);

        $(this).data('timer', timer);
    })

    $('#print_table').on('click', function (e) {
        e.preventDefault();
        window.print();
    })
    // end calculator

    // catalog filter
    $('#product-sort').change(function () {
        let option = $(this).find('option').filter(':selected');
        $.ajax({
            url: option.data('url'),
            method: 'GET',
            data: {'sort': option.data('sort'), 'value': option.data('value')},
            success : function (data) {
                document.location.href = option.data('url');
            }
        })
    })

    let origForm = $('#filter_form').serialize();

    $('#filter_actions').click(function () {
        $('input[name="filter_actions"]').val(0);
        if ($(this).hasClass('pal-active')) {
            $('input[name="filter_actions"]').val(1);
        }
        checkFilter(origForm, $('#filter_form').serialize());
    })

    $("#filter_form").on('keydown change', 'input, select', function() {
        checkFilter(origForm, $('#filter_form').serialize());
    });
    // end catalog filter

    // news
    $('.news-show_more').click(function (e) {
        let btn = $(this);
        $.ajax({
            url: btn.data('url'),
            method: 'GET',
            data: {'category': btn.data('category'), 'page': btn.data('page')},
            success : function (data) {
                btn.parent().siblings().append(data.html)

                if (false === data.has_next_page) {
                    btn.remove()
                } else {
                    let page = btn.data('page');
                    btn.data('page', ++page);
                }
            }
        })

        e.preventDefault();
    })

    $('.pal-articles--slider .pal-articles__item').height($('.pal-articles--slider .slick-track').outerHeight());
    // end news

    // globals
    if ($('table').hasClass('not__decorate') === false) {
        if ($('table').hasClass('pal-table') === false) {
            $('table').addClass('pal-table pal-table--strip2');
        }

        if ($('table').hasClass('pal-table') !== false) {
            if ($('table tbody tr:first-child').hasClass('extra') === false) {
                $('table tbody tr:first-child').addClass('extra');
            }
        }

        $('table tr').hover(function () {
            if ($(this).hasClass('extra')) {
                return;
            }
            $(this).addClass('hover');
        }, function () {
            $(this).removeClass('hover');
            }
        )
    }

    $('ul li.file a').each(function (k, v) {
        let html = renderLink(v);
        $(this).replaceWith(html);
    });

    $('ol li.file a').each(function (k, v) {
        let html = renderLink(v);
        $(this).replaceWith(html);
    });

    $('.search-text').on('keypress',function(e) {
        if (e.which == 13) {
            document.location.href = $(this).data('url') + '?s=' + $(this).val();
        }
    });

    $('.js-popup-close').click(function () {
        $('#answer-text').html('');
    })
    // end globals

    // order
    $('.company-activity').change(function () {
        let activity = $('.company-activity option:selected').data('activity');
        let url = $(this).data('url');
        $.ajax({
            url: url,
            method: 'POST',
            data: {'code' : activity},
            success: function (data) {
                $.each(data, function (k, v) {
                    $('.company-activity-code').append('<option value="'+v+'">'+v+'</option>');
                })
            }
        })
    })

    $('.achievement').fancybox();
    // end order
})
